body[data-theme="dark"] {
  @import '../sliders/dark-slider.scss';

  // Styles for slider tab
  .slider-tab {
    background-color: #8C8C8C;
  }

  // Styles for title elements
  .title-styles {
    color: white;
  }

  // Styles for header icons
  .header-icon {
    color: #3C342C;
    opacity: 0.8;
  }

  // Styles for slider images
  .slider-image {
    border: 5px solid #8C8C8C;
  }

  // Styles for language elements
  .language {
    background-color: #494949;
  }

  // Styles for link elements
  .link-href {
    color: white;
  }

  // Styles for project date elements
  .project-date {
    background-color: #918e97;
    color: white;
  }

  // Styles for header
  header {
    background-color: #494949;
    h1 {
      color: white;
    }
  }

  // Styles for about section
  #about {
    background-color: #7f7f7f;
    h1 span {
      color: white;
    }
    a {
      color: black;
    }
    .polaroid span {
      background:  #6d6d6d;
    }
    .card {
      background: #6d6d6d;
      color: white;
    }
  }

  // Styles for contact section
  #contact {
    background-color: #7f7f7f;
    h1 span {
      color: white;
    }
    a {
      color: black;
    }
    .polaroid span {
      background:  #6d6d6d;
    }
    .card {
      background: #6d6d6d;
      color: white;
    }
  }

  // Styles for portfolio section
  #portfolio {
    background: #5b5b5b;
    .section-title {
      color: white !important;
    }
    .foto div {
      background: #6d6d6d;
    }
    .project-title-settings {
      color: white;
    }
  }

  // Styles for resume section
  #resume {
    filter: brightness(80%);
    background: #5b5b5b;
    .section-title {
      color: white !important;
    }

    .experience-badge {
      background: #919191 !important;
      color: white;
    }

    .main-badge {
      background: #919191 !important;
    }

    .vertical-timeline-element-date {
      color: white;
    }

    .vertical-timeline-element-icon {
      background: #919191 !important;
    }
    @media only screen and (max-width: 1169px) {
      .vertical-timeline-element-date {
          color: black;
      }
    }
  }

  // Styles for modal content
  .modal-inside .modal-content {
    background: #5b5b5b;
    color: white;
  }

  // Styles for close icon
  .close-icon {
    color: white;
  }

  // Styles for footer and skills section
  footer, #skills {
    background: #1f1f1f;
  }
}
